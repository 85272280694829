
import { reactive, defineComponent, computed } from 'vue';

import { useToast } from 'primevue/usetoast';
import { IsNotEmpty, Length } from 'class-validator';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import FlexInput from '@/components/UI/Input.vue';

import { Export, ExportType } from '@/classes/models/Export';
import { FormHandler } from '@/classes/forms/FormHandler';
import CustomError from '@/classes/models/CustomError';
import { FormFields } from '@/interfaces/Input';
import ExportFormFields from '@/constants/FormFields/export';
import { MESSAGES } from '@/constants/errors';
import { VALID_ERRORS } from '@/constants/errors';
import { ExportApi } from '@/classes/api/ExportApi';

interface ExportNewInterface {
  name?: string;
  type?: ExportType;
}

class ExportNew implements ExportNewInterface{
  @IsNotEmpty({ message: VALID_ERRORS.required })
  @Length(3, 50, { message: VALID_ERRORS.length(3, 50) })
  name?: string;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  type?: ExportType;

  constructor(data: ExportNewInterface) {
    this.replaceBy(data);
  }

  replaceBy(data: ExportNewInterface) {
    this.name = data.name || undefined;
    this.type = data.type || undefined;
  }
}

export default defineComponent({
  emits: ['update:visible', 'onSubmit'],
  components: {
    Dialog, Button, FlexInput
  },
  props: {
    visible: Boolean,
    id: Number,
    editMode: Boolean,
  },

  setup(props, ctx) {
    const item = reactive<ExportNew>(new ExportNew({}));
    const fields = reactive<FormFields>({ ...ExportFormFields });

    const formHandler = new FormHandler(fields, item);
    const toast = useToast();
    const api = new ExportApi();

    const inputVisible = computed({
      get: () => props.visible,
      set: val => {
        ctx.emit('update:visible', val);
      },
    });

    const setItem = (itm: ExportNew) => {
      item.replaceBy(itm);
      formHandler.setObjectToValidate(item);
    }

    const resetValidations = () => {
      Object.values(fields).forEach(field => {
        field.invalid = false;
        field.validationErrors = [];
      });
    }

    const resetFormHandler = () => {
      setItem(new ExportNew({}));
      resetValidations();
    }

    const clone = (): Export => {
      return new Export({
        ...item,
      });
    }

    const fetch = async () => {
      if (props.editMode && props.id) {
        try {
          // setItem(await api.fetchById(props.id));
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
      } else {
        resetFormHandler();
      }
    }

    const submitHandler = async () => {
      const valid = await formHandler.checkValidations();
      if (valid) {
        try {
          if (!props.editMode) {
            await api.send(clone());
          } else {
            // await api.update(this.item);
          }
          ctx.emit('onSubmit');
          resetFormHandler();
          inputVisible.value = false;
          toast.add({
            severity: 'success',
            summary: 'Успешно',
            detail: 'Успешно добавлено',
            life: 3000,
          });
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
      } else {
        toast.add({
          severity: 'warn',
          summary: 'Некорректное заполнение',
          detail: MESSAGES.generalFormErrors,
          life: 3000,
        });
      }
    }

    return {
      inputVisible,
      fields,
      item,
      formHandler,
      resetFormHandler,
      submitHandler,
      fetch
    }
  }
});
