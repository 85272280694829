
import {ref, reactive, defineComponent, onMounted} from 'vue';

import {useToast} from 'primevue/usetoast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';

import CustomError from '@/classes/models/CustomError';
import {Export} from '@/classes/models/Export';
import { ExportApi } from '@/classes/api/ExportApi';
import {dateFilter} from '@/utils/filters';
import tooltips from '@/constants/tooltips';

import NewExportForm from '@/components/Export/ExportForm.vue';

export default defineComponent({
  components: {
    NewExportForm,
    DataTable, Column, Button
  },

  setup() {
    const item = reactive<Export>(new Export({}));
    const items = ref<Export[]>([]);
    const selected = ref<Export[]>([]);
    const editMode = ref(false);
    const loading = ref(false);
    const newWindow = ref(false);
    const tooltipsLocale = ref(tooltips);

    const toast = useToast();
    const api = new ExportApi();

    const filter = (date: string): string => {
      if (date) {
        return dateFilter(date, 'datetime');
      } else {
        return 'обращений нет';
      }
    }

    const update = async () => {
      loading.value = true;
      try {
        items.value = await api.fetchAll();
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    }

    const copyURL = (value: string) => {
      const dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = value;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      toast.add({
        severity: 'success',
        summary: 'Успешно',
        detail: 'Ссылка скопирована в буфер обмена',
        life: 3000,
      });
    }

    const downloadItem = (url: string) => {
      window.open(url, '_blank');
    }

    onMounted(() => {
      update();
    });

    return {
      item,
      items,
      editMode,
      selected,
      loading,
      newWindow,
      tooltipsLocale,
      copyURL,
      downloadItem,
      filter,
      update
    }
  }
});
