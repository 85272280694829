import { Entity } from './Entity';

export enum ExportType {
  DROM = 'Drom',
  BIBINET = 'Bibinet',
  JAPANCAR = 'Japancars',
  AUTORU = 'Autoru',
}

export interface Exports {
  data: Export[];
}

export class Export extends Entity<Export> {
  id?: number;
  name?: string;
  type? = ExportType.BIBINET;
  url?: string;
  enabled? = false;
  accessCount? = 0;
  lastAccess? = '';

  constructor(data: Export) {
    super();
    data.id && (this.id = data.id);
    data.name && (this.name = data.name);
    data.type && (this.type = data.type);
    data.url && (this.url = data.url);
    data.enabled && (this.enabled = data.enabled);
    data.accessCount && (this.accessCount = data.accessCount);
    data.lastAccess && (this.lastAccess = data.lastAccess);
  }
}
