import { Api } from './Api';
import { Export } from '../models/Export';
import CustomError from '../models/CustomError';

export class ExportApi extends Api {
  async fetchAll(): Promise<Export[]> {
    let exports: Export[] = [];
    try {
      const result = await this.fetchAllProtected<Export[]>('export');
      if (result) {
        exports = Export.createFromArray(Export, result);
      }
      return [...exports];
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async send(data: Export): Promise<Export> {
    try {
      const result = await this.sendProtected<Export>('export', data);
      return Export.createFromObject(Export, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async remove(id: number) {
    try {
      await this.removeProtected('export', id);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }
}
