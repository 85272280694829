import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlexInput = _resolveComponent("FlexInput")
  const _component_Button = _resolveComponent("Button")
  const _component_Dialog = _resolveComponent("Dialog")

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.inputVisible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputVisible = $event)),
    style: { width: '500px' },
    header: "Информация о экспорте",
    modal: true,
    class: "p-fluid",
    onShow: _ctx.fetch
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Очистить",
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _ctx.resetFormHandler
      }, null, 8, ["onClick"]),
      _createVNode(_component_Button, {
        label: "Сохранить",
        icon: "pi pi-check",
        class: "p-button-text",
        onClick: _ctx.submitHandler
      }, null, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(Object.keys(_ctx.fields), (key) => {
          return (_openBlock(), _createBlock(_component_FlexInput, {
            key: key,
            modelValue: _ctx.item[key],
            "onUpdate:modelValue": ($event: any) => (_ctx.item[key] = $event),
            title: _ctx.fields[key].title,
            id: key,
            type: _ctx.fields[key].type,
            variableType: _ctx.fields[key].variableType,
            options: _ctx.fields[key].options,
            invalid: _ctx.fields[key].invalid,
            validationErrors: _ctx.fields[key].validationErrors,
            onOnChange: ($event: any) => (_ctx.formHandler.checkValidation(key))
          }, null, 8, ["modelValue", "onUpdate:modelValue", "title", "id", "type", "variableType", "options", "invalid", "validationErrors", "onOnChange"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["visible", "onShow"]))
}