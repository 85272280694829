import { ExportType } from '@/classes/models/Export';
import { FormFields } from '@/interfaces/Input';

const fields: FormFields = {
  name: {
    title: 'Название',
    type: 'input',
  },
  type: {
    title: 'Площадка',
    type: 'select',
    options: [
      { title: 'drom.ru', value: ExportType.DROM },
      { title: 'bibinet.ru', value: ExportType.BIBINET },
      { title: 'japancars.ru', value: ExportType.JAPANCAR },
      { title: 'auto.ru', value: ExportType.AUTORU },
    ],
  },
};

export default fields;
